
export type Product = {
    id: string;
    name: string;
    type: string;
    desc: string;
    collection: number;

    price: number;

    variations: string[];
    colors: string[];

    // { variation-color: imgs[] }
    imgs: Record<string, string[]>;
}

export type ProductInstance = {
    productId: string;

    quantity: number;
    size: string;
    color?: string;
}

export function ProductInstancesEqual(p1: ProductInstance, p2: ProductInstance): boolean {
    return (p1.productId === p2.productId &&
            p1.size      === p2.size &&
            p1.color     === p2.color);
}
