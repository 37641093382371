import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import {Home} from "./pages/Home";
import {ProductPage} from "./pages/Product";

import {CartContextProvider} from "./contexts/Cart";
import {CartPage} from "./pages/Cart";

import "./App.scss";

export default function App() {
    return (
        <CartContextProvider>
        <Router>
            <Switch>
                <Route path="/product/:id" component={ProductPage} />
                <Route path="/cart" component={CartPage} />
                <Route path="/" component={Home} />
            </Switch>
        </Router>
        </CartContextProvider>
    );
}
