import {ProductInstance, ProductInstancesEqual} from "../../models/Product";
import {CartState, CartDispatches} from "./store";

export type CartReducersType = {
    [K in keyof CartDispatches]: (state: CartState, ...args: Parameters<CartDispatches[K]>) => CartState
}
export const CartReducers: CartReducersType = {
    addToCart: (state: CartState, product: ProductInstance) => {
        const existingIndex = state.products.findIndex((p) => ProductInstancesEqual(p, product));
        const curProduct = state.products[existingIndex];
        return {
            ...state,
            products: [
                ...state.products.slice(0, existingIndex),
                {
                    ...product,
                    quantity: ((curProduct?.quantity ?? 0) + product.quantity)
                },
                ...state.products.slice(existingIndex+1)
            ]
        };
    },
    removeFromCart: (state: CartState, product: ProductInstance) => {
        const existingIndex = state.products.findIndex((p) => ProductInstancesEqual(p, product));
        return {
            ...state,
            products: [
                ...state.products.slice(0, existingIndex),
                ...state.products.slice(existingIndex+1)
            ]
        };
    },
    changeQuantity: (state: CartState, product: ProductInstance, newAmount: number) => {
        const existingIndex = state.products.findIndex((p) => ProductInstancesEqual(p, product));
        return {
            ...state,
            products: [
                ...state.products.slice(0, existingIndex),
                {
                    ...product,
                    quantity: newAmount
                },
                ...state.products.slice(existingIndex+1)
            ]
        };
    }
}
