import React, {createContext, useState} from "react";
import Cookies from "js-cookie";

import {GetDispatches} from "../utils/reducers";

import {CartStore, CartState, CartDispatches} from "./store";
import {CartReducers} from "./reducers";

export const initialCartState = JSON.parse(Cookies.get("cart") ?? "{\"products\":[]}") as CartState;

export const CartContext = createContext<CartStore>({} as CartStore);
export function CartContextProvider({ children }: { children: React.ReactNode }) {
    const [state, setState] = useState(initialCartState);
    const dispatches = GetDispatches(state, (state) => {
        // Save cart to cookies
        Cookies.set("cart", JSON.stringify(state));
        setState(state);
    }, CartReducers) as CartDispatches;

    const value = { ...state, ...dispatches };
    return <CartContext.Provider value={value}>
        {children}
    </CartContext.Provider>
}
