import {Link} from "react-router-dom";
import {v4 as uuid} from "uuid";

import {Product} from "../../models/Product";

import "./index.scss";

type Props = {
    products: Product[];
}
export function ProductList({products}: Props) {
    return (
        <div className="grid">
            {products.map((product) => (
                <div className="grid__holder" key={uuid()}>
                    <Link to={`/product/${product.id}`}>
                        <div className="grid__product">
                            <img className="grid__product__img"
                                 src={`/products/collection${product.collection}/${product.imgs[product.variations[0]+"-"+product.colors[0]][0]}`}
                                 alt="Product preview" />
                            <span className="grid__product__name">{product.name}</span>
                            <span className="grid__product__price">${product.price}.00</span>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
}