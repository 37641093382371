import React from "react";

import {Nav} from "../../components/Nav";
import {Footer} from "../../components/Footer";
import {ProductList} from "../../components/ProductList";

import {Products} from "../../data/products";

import "./index.scss";


const featuredProducts = Object.values(Products).filter(p => p.collection === 1);

export function Home() {
    return (
        <div className="home">
            <Nav />
            <div className="home__featured">
                <ProductList products={featuredProducts} />
            </div>
            <Footer />
        </div>
    );
}
