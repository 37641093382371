import {Link} from "react-router-dom";

import "./index.scss";

import Amex from "./amex.svg";
import DinersClub from "./dinersclub.svg";
import Discover from "./discover.svg";
import JCB from "./jcb.svg";
import MasterCard from "./mastercard.svg";
import Paypal from "./paypal.svg";
import Venmo from "./venmo.svg";
import Visa from "./visa.svg";

export function Footer() {
    return (
        <footer className="footer">
            <div className="footer__links">
                <Link to="/shipping">Shipping</Link>
                <Link to="/refunds">Refunds</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-of-service">Terms of Service</Link>
                <Link to="/contact">Contact us</Link>
            </div>
            <div className="footer__payments">
                <img src={Amex} title="Amex" alt="Amex" />
                <img src={DinersClub} title="Diners Club" alt="Diners Club" />
                <img src={Discover} title="Discover" alt="Discover" />
                <img src={JCB} title="JCB" alt="JCB" />
                <img src={MasterCard} title="MasterCard" alt="MasterCard" />
                <img src={Paypal} title="Paypal" alt="Paypal" />
                <img src={Venmo} title="Venmo" alt="Venmo" />
                <img src={Visa} title="Visa" alt="Visa" />
            </div>
            <div className="footer__bottom">
                COPYRIGHT &#169; 2021, dumb art
            </div>
        </footer>
    );
}