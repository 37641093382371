import {useContext} from "react";
import {Link} from "react-router-dom";

import {CartContext} from "../../../contexts/Cart";
import {CalcItemsInCart, CalcPriceOfCart} from "../../../contexts/Cart/utils";

import {Products} from "../../../data/products";
import {ProductInstance} from "../../../models/Product";

import "./style.scss";

type Props = {
    productInstance: ProductInstance;
    onDismiss: () => void;
}
export function CartPopup({ productInstance, onDismiss }: Props) {
    const cart = useContext(CartContext);
    const product = Products[productInstance.productId];

    const numItemsInCart = CalcItemsInCart(cart);
    const totalPrice = CalcPriceOfCart(cart);

    const img = product.imgs[product.variations[0]+"-"+product.colors[0]][0];

    return (
        <>
            <div className="overlay" onClick={onDismiss}></div>
            <div className="cart__popup">
                <div className="cart__popup__top"><span onClick={onDismiss}>&times;</span></div>
                <div className="cart__popup__content">
                    <h4 className="cart__popup__content__msg">Product successfully added to your cart!</h4>
                    <div className="cart__popup__content__product">
                        <div className="cart__popup__content__product__left">
                            <img src={`/products/collection${product.collection}/${img}`}
                                 alt="Product preview" />
                        </div>
                        <div className="cart__popup__content__product__right">
                            <h2>{product.name} - {productInstance.size}</h2>
                            <h3>${product.price}.00</h3>
                            <div>Quantity: {productInstance.quantity}</div>
                            <div>There {numItemsInCart === 1 ? "is 1 item" : `are ${numItemsInCart} items`} in your cart</div>
                            <div className="cart__popup__content__product__right__total">Total: ${totalPrice}.00</div>
                        </div>
                    </div>
                    <div className="cart__popup__content__bottom">
                        <button onClick={onDismiss}>Continue Shopping</button>
                        <Link to="/cart"><button>Go to Cart</button></Link>
                    </div>
                </div>
            </div>
        </>
    );
}
