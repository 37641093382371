type Reducer<S> = (state: S, ...args: any) => S;
type Dispatch = (...args: any) => void;

export function GetDispatches<S>(state: S, setState: (state: S) => void, reducers: Record<string, Reducer<S>>): Record<string, Dispatch> {
    return Object.fromEntries(Object.entries(reducers).map(([key, reducer]) =>
        [key, (...args: any) => {
            const newState = {
                ...reducer(state, ...args)
            };
            setState(newState);
        }]
    ));
}
