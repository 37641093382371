import {Product} from "../models/Product";

const PRODUCTS = require("./products.json") as Record<string, Omit<Product, "id">>;

// Convert JSON to map of ID -> Product w/ ID as variable
export const Products =
    Object.fromEntries(
        Object.entries(PRODUCTS).map(
            ([id, p]) => [id, {
                id,
                ...p
            }]
        )
    );
