import {useContext} from "react";
import {Link} from "react-router-dom";

import {Products} from "../../data/products";
import {ProductInstance} from "../../models/Product";

import {CartContext} from "../../contexts/Cart";
import {CalcPriceOfCart} from "../../contexts/Cart/utils";

import {Nav} from "../../components/Nav";
import {Footer} from "../../components/Footer";

import "./style.scss";

export function CartPage() {
    const cart = useContext(CartContext);

    const totalPrice = CalcPriceOfCart(cart);

    function setQuantity(p: ProductInstance, n: number) {
        cart.changeQuantity(p, Math.min(Math.max(n, 1), 10));
    }

    return (
        <div className="cart__page">
            <Nav />
            <div className="cart__page__content">
                <h1>Your Shopping Cart</h1>
                <div className="cart__page__content__main">
                    {cart.products.length > 0 ?
                    (
                        <div>
                            {(cart.products.map((p, i) => {
                                const product = Products[p.productId];
                                const img = product.imgs[product.variations[0]+"-"+product.colors[0]][0];

                                return (
                                    <div className="cart__page__product" key={`cart-page-${i}-${p.productId}`}>
                                        <div className="cart__page__product__info">
                                            <img src={`/products/collection${product.collection}/${img}`}
                                                 alt="Product Preview" />
                                            <div>
                                                <h2>{product.name} - {p.size}</h2>
                                                <h3>${product.price}.00</h3>
                                            </div>
                                        </div>
                                        <div className="cart__page__product__config">
                                            <div className="cart__page__product__config__quantity">
                                                <span>Quantity</span>
                                                <div className="input__numeric">
                                                    <button className="input__numeric__minus" onClick={() => setQuantity(p, p.quantity-1)}>-</button>
                                                    <span>{p.quantity}</span>
                                                    <button className="input__numeric__plus"  onClick={() => setQuantity(p, p.quantity+1)}>+</button>
                                                </div>
                                            </div>
                                            <div className="cart__page__product__config__price">${product.price*p.quantity}.00</div>
                                            <div className="cart__page__product__config__remove" onClick={() => cart.removeFromCart(p)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }))}
                            <div className="cart__page__total">
                                Total: <span>${totalPrice}.00</span>
                            </div>
                        </div>
                    )
                    :
                    (
                        <div className="cart__page__content__empty">
                            <div>Nothing in your cart!</div>
                            <div><Link to="/">Please explore our collection!</Link></div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    )
}