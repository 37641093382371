import React, {useContext} from "react";
import {Link} from "react-router-dom";

import {CartContext} from "../../contexts/Cart";
import {CalcItemsInCart} from "../../contexts/Cart/utils";

import Banner from "./logo.jpg";
import ShoppingCart from "./shopping-cart.svg";
import Instagram from "./ig.svg";

import "./index.scss";

export function Nav() {
    const cart = useContext(CartContext);

    const numItems = CalcItemsInCart(cart);

    return (
        <header className="header">
            <div className="header__top">
                <div className="header__top__socials">
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/dumb.art.shop">
                        <img src={Instagram} alt="Instagram" />
                    </a>
                </div>
                <Link to="/cart">
                    <div className="header__top__incart">
                        ({numItems})
                    </div>
                    <div className="header__top__cart">
                        <img src={ShoppingCart} alt="Shopping Cart" />
                    </div>
                </Link>
            </div>

            <div className="header__logo">
                <Link to="/"><img src={Banner} alt="dumb art logo" /></Link>
            </div>

            <nav className="header__nav">
                <div>
                    <Link to="/products">ALL</Link>
                </div>
                <div>
                    <Link to="/products">COLLECTION 1</Link>
                </div>
                {/* <div>
                    <Link to="/products/hoodies">I LOVE TASH</Link>
                </div> */}
            </nav>
        </header>
    );
}
