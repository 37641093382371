import {useContext, useState} from "react";
import {useParams} from "react-router-dom";

// import ReactImageZoom from "react-image-zoom";
import ReactImageMagnify from "react-image-magnify";
import ReactSlick from "react-slick";

import {CartContext} from "../../contexts/Cart";

import {Products} from "../../data/products";
import {ProductInstance} from "../../models/Product";

import {Nav} from "../../components/Nav";
import {Footer} from "../../components/Footer";
import {CartPopup} from "./CartPopup";

import "./style.scss";

function Clamp(v: number, min: number, max: number) {
    return Math.min(Math.max(v, min), max);
}

type State = {
    size: string;
    variation: string;
    color: string;
    quantity: number;
    addedToCart: boolean;
}
export function ProductPage() {
    const {id} = useParams<{id: string}>();
    const product = Products[id];

    const [state, setState] = useState({variation: product?.variations[0],
                                        color: product?.colors[0],
                                        quantity: 1,
                                        addedToCart: false} as State);
    const {addToCart} = useContext(CartContext);

    const {size, variation, color, quantity, addedToCart} = state;

    function getInstance(): ProductInstance {
        return {
            productId: id,
            quantity,
            size
        };
    }

    function setSize(s: string) {
        setState({
            ...state,
            size: s
        });
    }
    function setVariation(v: string) {
        setState({
            ...state,
            variation: v
        });
    }
    function setColor(c: string) {
        setState({
            ...state,
            color: c
        });
    }
    function setQuantity(q: number) {
        setState({
            ...state,
            quantity: Clamp(q, 1, 10)
        });
    }
    function onAddClick() {
        addToCart(getInstance());
        setState({
            ...state,
            addedToCart: true
        });
    }
    function continueShopping() {
        setState({
            ...state,
            addedToCart: false
        });
    }

    return product ? (
        <div className="product">
            <Nav />
            <div className="product__info">
                <div className="product__info__left">
                    <ReactSlick dots infinite speed={500} slidesToShow={1} slidesToScroll={1}>
                        {product.imgs[variation+"-"+color].map((img, i) => (
                            <div key={`product__preview__${i}`}>
                                <ReactImageMagnify
                                    smallImage={{
                                        src: `/products/collection${product.collection}/${img}`,
                                        alt: "Product preview",
                                        isFluidWidth: true
                                    }}
                                    largeImage={{
                                        src:`/products/collection${product.collection}/${img}`,
                                        width: 1825,
                                        height: 2000
                                    }}
                                    isHintEnabled={true}
                                    shouldHideHintAfterFirstActivation={true}
                                    enlargedImagePosition="over"
                                    enlargedImageContainerStyle={{
                                        backgroundColor: "white",
                                        zIndex: 10
                                    }} />
                            </div>
                        ))}
                    </ReactSlick>

                    {/* <ReactImageZoom width={580}
                                    height={635}
                                    scale={0.5}
                                    img={`/products/${product.imgs[0]}`} /> */}
                    {/* <img src={`/products/${product.imgs[0]}`} alt="Product preview" /> */}
                </div>
                <div className="product__info__right">
                    <div className="product__info__desc">
                        <span className="product__info__desc__category">{product.type}</span>
                        <h1 className="product__info__desc__name">{product.name}</h1>
                        <h2 className="product__info__desc__price">
                            <span>$</span>
                            <span>{product.price.toFixed(2)}</span>
                        </h2>
                        {/* <p className="product__info__desc__desc">{product.desc}</p> */}
                    </div>
                    <div className="product__info__config">
                        <div className="product__info__config__size">
                            <span>Size</span>
                            <div>
                                <button className={`${size === "S" ? "selected" : ""}`}  onClick={() => setSize("S")}>S</button>
                                <button className={`${size === "M" ? "selected" : ""}`}  onClick={() => setSize("M")}>M</button>
                                <button className={`${size === "L" ? "selected" : ""}`}  onClick={() => setSize("L")}>L</button>
                                <button className={`${size === "XL" ? "selected" : ""}`} onClick={() => setSize("XL")}>XL</button>
                            </div>
                        </div>
                        <div className="product__info__config__quantity">
                            <span>Quantity</span>
                            <div className="input__numeric">
                                <button className="input__numeric__minus" onClick={() => setQuantity(quantity-1)}>-</button>
                                <span>{quantity}</span>
                                <button className="input__numeric__plus"  onClick={() => setQuantity(quantity+1)}>+</button>
                            </div>
                        </div>
                        {product.colors.length > 1 && (<div className="product__info__config__color">
                            <span>Color</span>
                            <div className="product__info__config__color__choose">
                                {product.colors.map((col, i) => (
                                    <div key={`product-color-${i}-${col}`}>
                                        <input data-image={col} id={col} type="radio" name="color"
                                               value={col} checked={color === col}
                                               onChange={() => setColor(col)} />
                                        <label htmlFor={col}>
                                            <span style={{
                                                backgroundColor: col
                                            }}></span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>)}
                        {product.variations.length > 1 && (<div className="product__info__config__variations">
                            <span>Variations</span>
                            <div className="product__info__config__variations__choose">
                                {product.variations.map((v, i) => (
                                    <div key={`product-variation-${i}-${v}`}>
                                        <input data-image={v} id={v} type="radio" name="color"
                                               value={v} checked={variation === v}
                                               onChange={() => setVariation(v)} />
                                        <label htmlFor={v}>
                                            <span>{v}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>)}
                    </div>
                    <div className="product__info__bottom">
                        <button onClick={() => onAddClick()} disabled={size === undefined}>Add to cart</button>
                    </div>
                </div>
            </div>
            <Footer />
            {addedToCart && (
                <CartPopup productInstance={getInstance()} onDismiss={() => continueShopping()} />
            )}
        </div>
    ) : (
        <div>404</div>
    );
}